import api from "../api";

const defaultPage = 1;
const defaultPageSize = 10;

class Dialogs {
	static async getCustomers(searchTerm) {
		let order = [["lastname", "ASC NULLS FIRST"]];
		let terms = searchTerm ? searchTerm.split(" ") : [];
		let q = [];
		for (let i = 0; i < terms.length; i++) {
			let term = terms[i];
			q.push({
				$or: [
					{
						firstname: {
							$ilike: "%" + term + "%",
						},
					},
					{
						lastname: {
							$ilike: "%" + term + "%",
						},
					},
					{
						"$EmailAddresses.emailAddress$": {
							$ilike: "%" + term + "%",
						},
					},
					{
						"$PhoneNumbers.phonenumberraw$": {
							$ilike: "%" + term + "%",
						},
					},
				],
			});
		}

		let result = await api.getCustomers({
			page: defaultPage,
			pageSize: defaultPageSize,
			where: {
				$and: q,
			},
			order: order,
		});

		return result.rows;
	}
}

export default Dialogs;
