<template>
	<v-card elevation="0" class="confirmation">
		<v-card-text>
			<div>
				<div class="schedule-header">
					<div class="icon">
						<img :src="require('@/assets/ls-contact-schedule-confirmation.svg')" alt="Scheduled" />
					</div>
					<h3 class="schedule-title">Confirmed</h3>
					<h6 class="schedule-subtitle" v-html="scheduledName"></h6>
				</div>
				<hr />
				<div class="schedule">
					<div class="time">
						<v-icon>icon-calendar-check-stroke</v-icon>
						<span>{{ scheduledDisplayTime }}</span>
					</div>
					<div class="timezone">
						<v-icon>icon-globe-website-stroke</v-icon>
						<span>{{ timeZoneDisplay }}</span>
					</div>
				</div>
				<hr />
				<div class="calendar-links">
					<div class="link-container">
						<a class="link" :href="appointment.googleCalendarUrl" target="_blank">
							<img :src="require('@/assets/ls-contact-google-logo.svg')" alt="Add to Google Calendar" />
							<span>Add to Google Calendar</span>
						</a>
						<a class="link" :href="icsText" download="event.ics">
							<img :src="require('@/assets/ls-contact-apple-logo.svg')" alt="Add to Apple Calendar" />
							<span>Add to Apple Calendar</span>
						</a>
						<a class="link" :href="icsText" download="event.ics">
							<img :src="require('@/assets/ls-contact-outlook-logo.svg')" alt="Add to Outlook Calendar" />
							<span>Add to Outlook Calendar</span>
						</a>
					</div>
				</div>
			</div>
		</v-card-text>
		<v-card-actions>
			<v-spacer />

			<!--Join-->
			<v-btn rounded color="primary" v-if="conversation" @click="join()">
				<v-icon left> icon-video </v-icon> Join
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
	import moment from "moment-timezone";

	export default {
		props: {
			appointment: Object,
			conversation: Object,
			name: String,
			phone: String,
			timeZone: String,
		},
		data() {
			return {};
		},
		mounted() {},
		methods: {
			join() {
				window.open(this.conversation.creatorUrl, "_blank");
			},
		},
		computed: {
			scheduledName() {
				let defaultTpl = "You are scheduled with ";
				if (!this || (!this.name && !this.phone)) {
					defaultTpl += "<i>(contact deleted)</i>";
				} else {
					defaultTpl += this.name && this.name.length > 0 ? this.name : this.phone;
				}
				return defaultTpl;
			},
			scheduledDisplayTime() {
				if (!this.appointment || !this.appointment.id) {
					return "";
				}

				const start = moment(this.appointment.startAt).tz(this.timeZone);
				const end = moment(this.appointment.endAt).tz(this.timeZone);
				const display = `${start.format("h:mma")} - ${end.format("h:mma")}, ${start.format("dddd, MMM D")}`;
				return display;
			},
			icsText() {
				if (!this.appointment || !this.appointment.id) {
					return "";
				}

				let icsText = `data:text/calendar;charset=utf-8,${encodeURIComponent(this.appointment.icsText)}`;
				return icsText;
			},
			timeZoneDisplay() {
				if (!this.timeZone) {
					return "";
				}

				const display = `${this.timeZone} - ${moment(this.appointment.startAt)
					.tz(this.timeZone)
					.format("z")}`.replaceAll("_", "");
				return display;
			},
		},
	};
</script>
<style scoped>
	.confirmation .schedule-header {
		text-align: center;
	}

	.confirmation hr {
		margin: 16px 0;
		color: #dfe0e2;
	}

	.confirmation .schedule-title {
		margin: 12px 0;
		background: linear-gradient(93deg, #019c9c 0%, #01bcbc 27.62%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.confirmation {
		color: #03011d;
		font-size: 1em;
	}

	.confirmation .time {
		margin-bottom: 8px;
	}

	.confirmation .time,
	.confirmation .timezone {
		line-height: 1.5;
	}

	.confirmation .schedule {
		padding: 0 24px;
		color: #9b9ea5;
	}

	.confirmation .schedule .icon {
		color: #0070ff;
	}

	.confirmation .meeting-link {
		font-size: 1rem;
		padding: 0 24px;
		margin-bottom: 16px;
		text-align: center;
	}

	.confirmation .calendar-links {
		font-size: 0.875em;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.confirmation .calendar-links .link {
		display: flex;
		margin-bottom: 16px;
		align-items: center;
		align-self: stretch;
		color: #03011d77;
	}

	.confirmation .calendar-links .link img {
		margin-right: 20px;
	}

	.confirmation .calendar-links .link:nth-child(3) {
		margin-bottom: 0px;
	}

	.confirmation .v-card__actions > button {
		width: 100%;
		height: 50px;
		border-radius: 25px;
		line-height: 20px;
		font-size: 1rem;
		font-weight: 600;
	}
</style>
