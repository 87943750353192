export const constants = {
	FEATURES: {
		INTEGRATIONS: "INTEGRATIONS",
	},
	CONVERSATION_STATUS: {
		NotStarted: "NotStarted",
		Unread: "Unread",
		Sent: "Sent",
		Delivered: "Delivered",
		InProgress: "InProgress",
		Processing: "Processing",
		ReadyForReview: "ReadyForReview",
		Reviewed: "Reviewed",
		Scheduled: "Scheduled",
	},
	CONVERSATION_TYPE: {
		ASYNC: "async",
		SYNC: "sync",
	},
	INITIATION_TYPE: {
		ADMIN: "admin",
		BEACON: "beacon",
		SELF: "self",
	},
	SCHEDULER_STATE: {
		SCHEDULE: "schedule",
		SELECT_DATE: "select-date",
		SELECT_TIME: "select-time",
		CONFIRMATION: "confirmation",
	},
	DIRECTION: {
		INBOUND: "INBOUND",
		OUTBOUND: "OUTBOUND",
	},
	areEqual: function (typeName1, typeName2) {
		return typeName1 && typeName2 && typeName1.toUpperCase() == typeName2.toUpperCase();
	},
};
export default constants;
