import Vue from "vue";
import Router from "vue-router";
import * as Sentry from "@sentry/browser";

import api from "../api";

const HomePage = () => import("../components/HomePage.vue");
const LoadingPage = () => import("../components/LoadingPage.vue");
const CustomersPage = () => import("../components/CustomersPage.vue");
const CustomerPage = () => import("../components/CustomerPage.vue");
const UsersPage = () => import("../components/UsersPage.vue");
const UserPage = () => import("../components/UserPage.vue");
const OrganizationsPage = () => import("../components/OrganizationsPage.vue");
const OrganizationPage = () => import("../components/OrganizationPage.vue");
const WebsitesPage = () => import("../components/WebsitesPage.vue");
const WebsitePage = () => import("../components/WebsitePage.vue");
const ConversationsPage = () => import("../components/ConversationsPage.vue");
const ConversationPage = () => import("../components/ConversationPage.vue");
const SubscriptionPage = () => import("../components/SubscriptionPage.vue");
const PaymentSuccess = () => import("../components/PaymentSuccess.vue");
const AttributesPanel = () => import("../components/AttributesPanel.vue");

Vue.use(Router);

// ignore errors on re-navigate
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			// BEFORE:
			return { selector: to.hash, behavior: "smooth" };

			//return { el: to.hash };
		}
	},
	routes: [
		{
			path: "/",
			name: "home",
			component: HomePage,
		},
		{
			path: "/callback",
			name: "callback",
			component: LoadingPage,
		},
		{
			path: "/payment-success",
			name: "paymentSuccess",
			component: PaymentSuccess,
		},
		{
			path: "/logout",
			name: "logout",
			component: HomePage,
		},
		{
			path: "/contacts",
			name: "contacts",
			component: CustomersPage,
		},
		{
			path: "/contacts/:id",
			name: "contact",
			meta: {
				entityTableName: 'customers',
			},
			components: {
				default: CustomerPage,
				inner: AttributesPanel,
			}
		},
		{
			path: "/users",
			name: "users",
			component: UsersPage,
		},
		{
			path: "/users/:id",
			name: "user",
			component: UserPage,
		},
		{
			path: "/websites",
			name: "websites",
			component: WebsitesPage,
		},
		{
			path: "/websites/:id",
			name: "website",
			component: WebsitePage,
		},
		{
			path: "/organizations",
			name: "organizations",
			component: OrganizationsPage,
		},
		{
			path: "/organizations/:id",
			name: "organization",
			component: OrganizationPage,
		},
		{
			path: "/subscriptions/:id",
			name: "subscription",
			component: SubscriptionPage,
		},
		{
			path: "/conversations",
			name: "conversations",
			components: {
				default: ConversationsPage
			}
		},
		{
			path: "/conversations/:id",
			name: "conversation",
			meta: {
				entityTableName: 'conversations',
			},
			components: {
				default: ConversationPage,
				inner: AttributesPanel,
			}
		},
		{
			path: "/conversations/ical/:id",
			name: "conversation-ical",
			component: HomePage,
		},
		{
			path: "*",
			name: "notfound",
			component: HomePage,
		},
	],
});

// very basic "setup" of a global guard
router.beforeEach(async (to, from, next) => {
	if (to.name == "callback") {
		// check if "to"-route is "callback" and allow access
		router.app.$auth
			.handleAuthentication()
			.then(function () {
				// weird load order issues on the main page
				// causes a timing issue here, so just reload one final time after we authenticate
				let path = localStorage.getItem("login-path");
				window.location.href = path || "/";
				// TODO: could store/retrieve path here
			})
			.catch(function () {
				if (!router.app.$auth.isBlocked) {
					router.app.$auth.login({
						path: to.path,
					});
				}
			});
	} else if (to.name.indexOf("conversation-ical") != -1 && router.app.$auth.isAuthenticated()) {
		if (to.params.id) {
			const conversationId = to.params.id;
			const conversation = await api.getConversation(conversationId);

			if (conversation) {
				window.location.href = conversation.creatorUrl;
			} else {
				console.warn(`Conversation: Status=NotFound, Id=${conversationId}`);
			}
		}
	} else if (router.app.$auth.isAuthenticated()) {
		// if authenticated allow access
		// redirect some domains...would be nice to do this elsewhere
		// we allow the app to be authenticated, then redirect after
		const redirectFrom = ["app.supbubble.com", "contact.liveswitch.com"];
		const redirectTo = "app.contact.liveswitch.com";
		for (let i = 0; i < redirectFrom.length; i++) {
			// check root url
			if (window.location.host == redirectFrom[i]) {
				let target = new URL("https://" + redirectFrom[i]);
				target.hostname = redirectTo;
				window.location.href = target;
				// bail out of the whole function without invoking next()
				return;
			}
		}

		// check if we're loaded via the api
		if (!router.app.$loaded) {
			let me = null;
			try {
				me = await api.getMe(true, false, false);
				if (!me) {
					// should not happen, as this only runs after auth
					alert("What...");
				}
			} catch (e) {
				//console.log(e)
				if (e.code == 401) {
					// we don't can't find a user in the database. Show "no account" modal
					router.app.$root.$noAccount.open();
					return;
				} else {
					if (e.code) {
						alert(e.error); // + ' (Response code: ' + e.code + ')')
					} else {
						if (window.location.hostname == "localhost") {
							alert("Could not reach the server. Please make sure the API service is running.");
						}
					}
				}
			}
			router.app.$loaded = true;

			// SENTRY CONTEXT AREA
			if (me) {
				// Populate the sentry context for what ever information we know at this time.
				Object.keys(me).forEach((key) => {
					if (!Array.isArray(me[key])) {
						Sentry.setContext(key, me[key]);
					}
				});
				// This is a special extra setup to help track the user.
				if (me.user) {
					// Track the current user (allows seeing multiple errors for a user).
					Sentry.setUser({ id: me.user.id ?? "", email: me.user.email ?? "", username: me.user.email ?? "" });
				}
			}
			// This will give you information of the last path they went to and came from.
			if (to) Sentry.setContext("to", to);
			if (from) Sentry.setContext("from", from);
			// END SENTRY CONTEXT AREA

			// no orgs means we move to the setup
			if (me.organization && me.organization.id) {
				console.log("starting");
				api.setOrganizationId(me.organization.id);
				router.app.$root.$organization = me.organization;
				//localStorage.setItem("org", JSON.stringify(me.organization));
				//const params = new Proxy(new URLSearchParams(window.location.search), {
				//    get: (searchParams, prop) => searchParams.get(prop),
				//  });
				//if (params.acceptSMS) {
				//    router.app.$root.$setupAccount.open()
				//}
				next();
			} else {
				router.app.$root.$setupAccount.open();
				next();
			}
		} else {
			console.log("routing");
			next();
		}
	} else {
		// trigger auth0 login
		localStorage.setItem("login-path", to.path || window.location.pathname);
		router.app.$auth.login({
			path: to.path,
		});
	}
});

/* Capture and log router errors.  */
router.onError((error, to) => {
	try {
		Sentry.captureException(error, {
			tags: {
				file: "router/index.js",
			},
			extra: {
				to: to,
			},
		});
	} catch (e) {}
});

export default router;
