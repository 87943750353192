<template>
	<v-app>
		<v-overlay v-model="overlay" z-index="0" opacity=".76"></v-overlay>
		<main class="main">
			<v-row
				v-if="ready"
				no-gutters
				class="justify-center mb-0"
				:class="$vuetify.breakpoint.smAndDown ? 'mobile-container-row' : ''">
				<v-col cols="11" md="3" lg="4" class="on-top">
					<img
						id="liveswitch-logo"
						v-if="!organizationLogo"
						:class="posterClass"
						src="@/assets/ls-contact-3d-color-logo-white-font.svg" />
					<img v-else :class="posterClass" :src="organizationLogo" />
				</v-col>
				<v-col cols="11" md="7" lg="4" :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mt-12'">
					<v-card class="rounded-xl" style="overflow: hidden">
						<div class="card-inner">
							<v-row no-gutters class="header pa-4">
								<v-col cols="6" class="text-h5 my-auto pl-3"
									><span id="header-text" class="font-weight-600">{{ model.headline }}</span></v-col
								>
								<v-col cols="6">
									<img
										:src="
											require('@/assets/ls-contact-dashboard-recording-request-image-desktop.webp')
										"
										class="recording-video-img"
										alt="A person recording a video with his cell phone." />
								</v-col>
							</v-row>

							<div v-if="shareUrl" class="recording-information pa-4">
								<div style="font-size: 16px">
									The link has been saved to your clipboard! Some devices limit your ability to copy
									to the clipboard. In that case, you can copy the link below. For best results, we
									recommend opening the link and recording on your mobile or tablet device.
								</div>
								<div class="mt-4" style="font-size: 16px; word-break: break-all; display: flex">
									<v-tooltip
										v-model="linkCopiedBubble"
										right
										content-class="copied-bubble"
										style="z-index: 9999">
										<template v-slot:activator="{}">
											<v-btn
												elevation="0"
												class="copy-recording-link"
												:class="linkCopiedBubble ? 'copied' : ''"
												@click="copyLinkBubble">
												<i class="icon-link-double"></i>
											</v-btn>
										</template>
										<span>Copied!</span>
									</v-tooltip>
									<div>
										<a :href="shareUrl" target="_blank">{{ shareUrl }}</a>
									</div>
								</div>
							</div>

							<v-form v-if="!shareUrl" lazy-validation ref="form" class="pa-4">
								<v-container v-if="formReady">
									<div v-for="(field, index) in model.FormFields" :key="field.name">
										<div style="position: relative">
											<v-text-field
												v-if="isEmailField(field)"
												outlined
												type="email"
												v-model="formData[field.id]"
												hide-details="auto"
												@input="inputChanged"
												:autofocus="index == 0"
												:autocomplete="Math.random()"
												:rules="getValidateRules(field)"
												:label="getLabel(field)"
												:placeholder="field.placeholder"></v-text-field>

											<div v-else-if="isPhoneNumberField(field)" @paste.prevent="pasteEvent">
												<vue-tel-input
													valid-color="#000000"
													v-bind="vueTelProps"
													v-model="phoneNumber"
													@validate="validatePhoneNumber"
													@input="matchPhone"
													@blur="phoneNumberBlur"
													:autofocus="index == 0"
													:border-radius="0"
													:validCharactersOnly="true"
													:required="isRequired(field)"
													:inputOptions="{
														placeholder: 'Enter a Phone Number *',
													}"
													:style="{
														height: '54px',
														marginBottom:
															!validPhoneNumber && (phoneNumber || phoneNumberTouched)
																? '8px'
																: '12px',
														border:
															!validPhoneNumber && (phoneNumber || phoneNumberTouched)
																? '2px solid red !important'
																: '',
													}" />
												<div
													v-if="(phoneNumber || phoneNumberTouched) && !validPhoneNumber"
													class="v-text-field__details"
													style="margin-bottom: 8px; padding: 0 12px">
													<div class="v-messages theme--light error--text" role="alert">
														<div class="v-messages__wrapper">
															<div class="v-messages__message">
																{{
																	field.FieldValidators?.find(
																		(x) => x.type == FieldValidationType.Phone
																	)?.errorMessageTpl
																}}
															</div>
														</div>
													</div>
												</div>
											</div>

											<v-text-field
												v-else-if="field.type == FieldType.Text"
												outlined
												v-model="formData[field.id]"
												hide-details="auto"
												@input="inputChanged"
												:autofocus="index == 0"
												:autocomplete="Math.random()"
												:rules="getValidateRules(field)"
												:label="getLabel(field)"
												:placeholder="field.placeholder"
												class="field-border-radius"></v-text-field>

											<v-text-field
												v-else-if="field.type == FieldType.Date"
												type="date"
												outlined
												v-model="formData[field.id]"
												hide-details="auto"
												@input="inputChanged"
												:autofocus="index == 0"
												:autocomplete="Math.random()"
												:rules="getValidateRules(field)"
												:label="getLabel(field)"
												:placeholder="field.placeholder"
												class="field-border-radius"></v-text-field>

											<v-text-field
												v-else-if="field.type == FieldType.Number"
												type="text"
												outlined
												v-model="formData[field.id]"
												hide-details="auto"
												@input="inputChanged"
												:autofocus="index == 0"
												:autocomplete="Math.random()"
												:rules="getValidateRules(field)"
												:label="getLabel(field)"
												:placeholder="field.placeholder"
												class="field-border-radius"></v-text-field>

											<v-text-field
												v-else-if="field.type == FieldType.Number"
												outlined
												v-model="formData[field.id]"
												hide-details="auto"
												@input="inputChanged"
												:autofocus="index == 0"
												:autocomplete="Math.random()"
												:rules="getValidateRules(field)"
												:label="getLabel(field)"
												:placeholder="field.placeholder"
												class="field-border-radius"></v-text-field>

											<v-textarea
												v-else-if="field.type == FieldType.TextArea"
												outlined
												v-model="formData[field.id]"
												hide-details="auto"
												@input="inputChanged"
												:autofocus="index == 0"
												:autocomplete="Math.random()"
												:rows="2"
												:rules="getValidateRules(field)"
												:label="getLabel(field)"
												:placeholder="field.placeholder" />
										</div>
									</div>

									<div class="text-h7">* indicates a required field</div>

									<v-layout row wrap class="button justify-end mt-2">
										<div
											ref="recaptchaElement"
											class="g-recaptcha"
											data-callback="onSubmit"
											data-size="invisible"></div>

										<v-flex shrink>
											<v-btn
												id="send-recording-invite-btn"
												:loading="saving"
												color="primary"
												class="my-4 text-right"
												height="64px"
												style="font-size: 20px"
												@click="validateForm">
												<span style="font-size: 20px">{{ "Create Recording Link" }}</span>
												<v-icon size="22" style="color: white" class="px-2"
													>icon-circle-arrow-right-stroke</v-icon
												>
											</v-btn>
										</v-flex>
									</v-layout>
								</v-container>
							</v-form>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" md="2" lg="4"></v-col>
			</v-row>
			<v-snackbar id="snackbar" v-model="showSnackbar" top :timeout="snackbarTimeout">
				{{ errorMessage }}
			</v-snackbar>
		</main>
	</v-app>
</template>

<script lang="ts" setup>
	import FormField from "./types/forms/FormField";
	import Form from "./types/forms/Form";
	import { onMounted, ref, computed, getCurrentInstance } from "vue";
	import { FieldType } from "./types/forms/FieldType";
	import { FieldValidationType } from "./types/forms/FieldValidationType";

	import api from "@/api";
	import { VuetifyValidation } from "./helpers/Validation";
	import { FormSubmissionField } from "./types/forms/FormSubmissionField";
	import { FormSubmission } from "./types/forms/FormSubmission";
	import * as Sentry from "@sentry/browser";

	const overlay = ref<boolean>(true);
	const ready = ref<boolean>(false);
	const formReady = ref<boolean>(false);
	const showSnackbar = ref<boolean>(false);
	const linkCopiedBubble = ref<boolean>(false);
	const snackbarTimeout = ref<number>(5000);
	const model = ref<Form>({} as Form);
	const organizationLogo = ref<string>();
	const shareUrl = ref<string>("");
	const instance = ref();
	const form = ref();
	const validPhoneNumber = ref<boolean>(false);
	const validationRules: Map<string, Array<Function>> = new Map<string, Array<Function>>();
	const formData = ref({});
	const saving = ref<boolean>(false);
	const validForm = ref<boolean>(false);
	const phoneNumber = ref<string>();
	const phoneNumberTouched = ref<boolean>(false);
	const errorMessage = ref<string>("Unfortunately we could not process your request at this time. Please try again.");
	const recaptchaSiteKey = ref<string>(window.env.VITE_RECAPTCHA_SITE_KEY);
	const recaptchaElement = ref();
	const captchaLoadCount = ref(0);
	const vueTelProps = {
		preferredCountries: ["US", "AU", "CA", "GB"],
		mode: "international",
		required: true,
		showDialCodeInSelection: true,
		dropdownOptions: {
			showFlags: true,
			showDialCodeInSelection: true,
		},
		inputOptions: {
			autocomplete: Math.random(),
			id: "",
			maxlength: 16,
			name: "telephone",
			placeholder: "Enter a Phone Number *",
			tabindex: "0",
			"aria-describedby": "",
			type: "tel",
			required: true,
		},
		defaultCountry: "",
		disabledFormatting: false,
		wrapperClasses: "country-phone-input",
		validCharactersOnly: true,
	};

	onMounted(async () => {
		instance.value = getCurrentInstance();
		ready.value = true;
		loadCaptcha();

		try {
			const hash = window.location.hash;
			if (hash != "") {
				const formId = hash.substring(1);
				const response = await api.get(`/public/forms/${formId}`);
				setupFormData(response.form);
				model.value = response.form;
				organizationLogo.value = response.organizationLogoUrl;
				setValidationRules();
				formReady.value = true;
			}
		} catch (error) {
			// if form doesnt exist, we don't want to log it to Sentry
		}
	});

	function loadCaptcha() {
		// If reCAPTCHA takes longer than 5 seconds to long, something went wrong. We'll show an error to the user when they try to submit the form

		if (captchaLoadCount.value > 10) {
			console.warn("reCAPTCHA failed to load");
			return;
		}

		if (!window.captchaLoaded || !recaptchaElement.value) {
			setTimeout(loadCaptcha, 500);
			captchaLoadCount.value++;
			return;
		}

		grecaptcha.render(recaptchaElement.value, {
			sitekey: recaptchaSiteKey.value,
			callback: async (response) => {
				console.log("reCAPTCHA Validation: Status=Success");
				await submitForm();
			},
		});
	}

	function setupFormData(form: Form) {
		for (let field of form.FormFields) {
			formData.value[field.id] = "";
		}
	}

	function setValidationRules() {
		for (let field of model.value.FormFields) {
			const rules: Function[] = validationRules.get(field.id) ?? [];

			for (let validator of field.FieldValidators) {
				switch (validator.type) {
					case FieldValidationType.Custom:
						// Todo type these.
						if (validator.customValidator === "FirstAndLastName") {
							rules.push(VuetifyValidation.NameRule(validator.errorMessageTpl).isValid);
						} else if (validator.customValidator === "EmailOrPhone") {
							// TODO: MK - don't think we need this right now but leaving for reference in the future if we decide we need it
							//emailOrPhoneFields.add(field.id);
							//rules.push(CheckEmailAndPhone(validator.errorMessageTpl));
						} else if (validator.customValidator === 'DecimalNumber'){
							rules.push(VuetifyValidation.DecimalNumberValidation(validator.errorMessageTpl).isValid)
						}
						break;
					case FieldValidationType.Email:
						rules.push(VuetifyValidation.EmailValidation(validator.errorMessageTpl).isValid);
						field.type = FieldType.Email;
						break;
					case FieldValidationType.Phone:
						rules.push(VuetifyValidation.PhoneNumberValidation(validator.errorMessageTpl).isValid);
						field.type = FieldType.Phone;
						break;
					case FieldValidationType.MaxLength:
						if (validator.value != null && validator.value != undefined && isValidNumber(validator.value)) {
							rules.push(
								VuetifyValidation.MaxLengthValidation(
									parseInt(validator.value),
									validator.errorMessageTpl
								).isValid
							);
						}
						break;
					case FieldValidationType.MaxValue:
						if (validator.value != null && validator.value != undefined && isValidNumber(validator.value)) {
							rules.push(
								VuetifyValidation.MaxValueValidation(
									parseInt(validator.value),
									validator.errorMessageTpl
								).isValid
							);
						}
						break;
					case FieldValidationType.MinLength:
						if (validator.value != null && validator.value != undefined && isValidNumber(validator.value)) {
							rules.push(
								VuetifyValidation.MinLengthValidation(
									parseInt(validator.value),
									validator.errorMessageTpl
								).isValid
							);
						}
						break;
					case FieldValidationType.MinValue:
						if (validator.value != null && validator.value != undefined && isValidNumber(validator.value)) {
							rules.push(
								VuetifyValidation.MinValueValidation(
									parseInt(validator.value),
									validator.errorMessageTpl
								).isValid
							);
						}
						break;
					case FieldValidationType.Required:
						rules.push(VuetifyValidation.RequiredValidationField(validator.errorMessageTpl).isValid);
						if (field.type == FieldType.Number) {
							rules.push(VuetifyValidation.NumberRequiredValidationField(validator.errorMessageTpl).isValid)
						} else {
							rules.push(VuetifyValidation.RequiredValidationField(validator.errorMessageTpl).isValid)
						}
						break;
				}
			}
			validationRules.set(field.id, rules);
		}
	}

	function getValidateRules(field: FormField) {
		const rules = validationRules.get(field.id);
		return rules;
	}

	function isValidNumber(str: string): boolean {
		if (str.trim() === "") {
			return false; // Empty or whitespace-only strings are not valid numbers
		}
		return !isNaN(str);
	}

	function pasteEvent(event: ClipboardEvent) {
		phoneNumber.value = event.clipboardData?.getData("text")?.replace(/\D/g, "");
	}

	function validatePhoneNumber(args: any) {
		validPhoneNumber.value = args.valid;
	}

	function matchPhone(number: string, phoneObject: any) {
		if (!validPhoneNumber.value) return;
		const phoneField = model.value.FormFields.find((x) => x.type == FieldType.Phone);

		if (phoneField) {
			phoneNumber.value = phoneObject.formatted;
		}
	}

	function phoneNumberBlur() {
		phoneNumberTouched.value = true;
	}

	function inputChanged() {
		validForm.value = form.value.value && validPhoneNumber.value;
		console.log(validForm.value);
	}

	function verifyCaptcha() {
		try {
			grecaptcha.execute();
		} catch (err) {
			Sentry.captureException(err, {
				tags: {
					method: "verifyCaptcha",
					file: "WebForm",
				},
			});
			showSnackbar.value = true;
		}
	}

	function validateForm() {
		const valid = form.value.validate();
		phoneNumberTouched.value = true;

		if (!valid || !validPhoneNumber.value) {
			return;
		}

		verifyCaptcha();
	}

	async function submitForm() {
		saving.value = true;
		const fieldSubmissions = new Array<FormSubmissionField>();

		try {
			for (let id of Object.keys(formData.value)) {
				const field = model.value.FormFields.find((x) => x.id == id);

				if (!field) {
					continue;
				}

				let type = FieldType.Text;

				if (field.type == FieldType.Date) {
					type = FieldType.Date;
				} else if (field.type == FieldType.DateTime) {
					type = FieldType.DateTime;
				} else if (field.type == FieldType.Number) {
					type = FieldType.Number;
				} else if (field.type == FieldType.Phone) {
					formData.value[id] = phoneNumber.value;
				}

				const fieldSubmission = {
					fieldId: id,
					type: type,
					value: formData.value[id],
				} as FormSubmissionField;
				fieldSubmissions.push(fieldSubmission);
			}

			const formSubmission = {
				formId: model.value.id,
				organizationId: model.value.organizationId,
				FormSubmissionFields: fieldSubmissions,
				forcedUserId: model.value.createdBy,
			} as FormSubmission;

			const response = await api.submitCustomWebForm(formSubmission);
			shareUrl.value = response.customerUrl;

			if (navigator.clipboard) {
				try {
					await navigator.clipboard.writeText(shareUrl.value);
				} catch {
					console.warn("Failed to copy link to clipboard");
				}
			}
		} catch (err) {
			Sentry.captureException(err, {
				tags: {
					method: "submitForm",
					file: "WebForm",
				},
				extra: {
					formId: model.value.id,
					formData: formData.value,
				},
			});
		} finally {
			saving.value = false;
		}
	}

	const posterClass = computed(() => {
		const posterClass = organizationLogo.value ? "org-poster" : "poster";
		const sizeSpecificClass = instance.value?.proxy.$vuetify.breakpoint.smAndDown ? "" : "my-12";
		return `${posterClass} ${sizeSpecificClass} mx-auto`;
	});

	async function copyLinkBubble() {
		linkCopiedBubble.value = true;
		if (navigator.clipboard) {
			try {
				await navigator.clipboard.writeText(shareUrl.value);
			} catch {
				console.warn("Failed to copy link to clipboard");
			}
		}
		setTimeout(() => {
			linkCopiedBubble.value = false;
		}, 2000);
	}

	function isRequired(field: FormField): boolean {
		const validator = field.FieldValidators?.find((x) => x.type == FieldValidationType.Required);
		return validator != null;
	}

	function isPhoneNumberField(field: FormField): boolean {
		if (field.FieldValidators?.find((x) => x.type == FieldValidationType.Phone)) {
			return true;
		}

		return false;
	}

	function isEmailField(field: FormField): boolean {
		if (field.FieldValidators?.find((x) => x.type == FieldValidationType.Email)) {
			return true;
		}

		return false;
	}

	function getLabel(field: FormField, requiredOverride?: boolean) {
		const required = requiredOverride || isRequired(field);
		const label = `${field.name}${required ? " *" : ""}`;
		return label;
	}
</script>

<style lang="scss" scoped>
	$xs: 402px;
	$small: 600px;
	$medium: 900px;
	$large: 901px;

	#app {
		display: block !important;
	}
	nav .v-icon {
		padding-right: 8px;
		font-size: 18px;
	}
	.action {
		cursor: pointer;
	}
	.main {
		background-image: url("@/assets/ls-contact-loader-wallpaper-v2.svg");
		background-size: cover;
		background-position: center;
		background-color: #f5f5f5;
		min-height: 100vh;
	}
	.main .header {
		background-color: #dff0ff;
	}

	.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
		background-color: #0070ff !important;
		opacity: 0.24;
	}
	.theme--light.v-btn.v-btn--disabled {
		color: white !important;
	}
	.theme--light.v-btn.v-btn--disabled .v-icon,
	.theme--light.v-btn.v-btn--disabled .v-btn__loading {
		color: white !important;
	}

	.text-h5 {
		@media screen and (max-width: $small) {
			font-size: 1rem !important;
		}
	}

	.recording-video-img {
		display: flex;
		max-width: 100%;
		max-height: 200px;
		margin-top: auto;
		margin-left: auto;
		margin-right: auto;
		@media screen and (max-width: $small) {
			max-height: 150px;
		}
	}
	.v-snack__content {
		font-size: 1rem;
	}

	.mobile-container-row {
		padding-top: 20px !important;
		padding-bottom: 70px;
	}
	.poster {
		height: 50px;
		display: flex;
		@media screen and (max-width: $medium) {
			display: initial;
		}
	}
	.org-poster {
		height: 125px;
		display: flex;
		@media screen and (max-width: $medium) {
			display: initial;
			height: 50px;
		}
	}
	.on-top {
		z-index: 5 !important;
	}

	.recording-information {
		padding: 15px;
	}

	#create-new-link {
		font-size: 14px;
		border: 1px solid #fff;
		margin-top: 10px;
	}

	.copy-recording-link {
		margin-right: 8px;
	}

	.button .v-btn {
		border-radius: 12px;
	}
</style>
