<template>
	<v-system-bar
		class="subscription"
		app
		style="z-index: 5"
		:color="isTrialCCNow ? 'var(--v-primary-base)' : '#e84867'">
		<span class="inner">
			<v-icon color="white">icon-info-circle-stroke</v-icon>
			<b>
				<span v-if="isEndedTrial"> Your trial has expired. Please select a plan to continue. </span>
				<span v-else-if="isTrialCCNow">
					Welcome! Select your plan and follow the prompts to start your free trial.
				</span>
				<span v-else>
					{{ trialDaysRemaining }} Days Remaining! You're on a trial. Ready for the full experience?
					<a @click="$emit('upgrade')">Upgrade now</a>
				</span>
			</b>
		</span>
	</v-system-bar>
</template>
<script>
	export default {
		name: "TrialTopBar",
		props: {
			isEndedTrial: Boolean,
			isTrialCCNow: Boolean,
			trialDaysRemaining: Number,
		},
	};
</script>
<style scoped>
	.v-system-bar.subscription span {
		margin: auto;
	}
	.v-system-bar.subscription span,
	.v-system-bar.subscription span a {
		color: white;
	}

	.v-system-bar.subscription span a {
		text-decoration: underline;
	}
</style>
