<template>
	<div class="d-flex" :class="{ flex: buttonFabTextPrefix }">
		<v-menu
			@input="menuInput"
			v-model="menu"
			content-class="vue-tel-input-menu new-conversation"
			:close-on-content-click="false"
			:close-on-click="true"
			:min-width="maxWidth"
			:max-width="maxWidth"
			nudge-width="250"
			nudge-left="125"
			:nudge-top="buttonFabTextPrefix ? 600 : 100"
			offset-y
			top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-if="buttonFab"
					:id="`${buttonId ? buttonId : 'action-fab'}`"
					:class="`action-fab-${action}`"
					v-bind="attrs"
					v-on="on"
					fab
					medium
					@click="selectAction">
					<v-icon class="action-button-icon">{{ buttonIcon }}</v-icon>
				</v-btn>
				<div
					class="d-flex flex align-center justify-end"
					v-else-if="buttonFabTextPrefix"
					v-bind="attrs"
					v-on="on">
					<h5 class="fab-action-header flex">{{ buttonText }}</h5>
					<v-btn
						:id="`${buttonId ? buttonId : 'action-fab'}`"
						:class="`action-fab-${action}`"
						v-bind="attrs"
						v-on="on"
						fab
						medium
						@click="selectAction">
						<v-icon class="action-button-icon">{{ buttonIcon }}</v-icon>
					</v-btn>
				</div>
				<v-btn
					v-else
					:id="`${buttonId ? buttonId : 'action-button'}`"
					v-bind="attrs"
					v-on="on"
					:fab="buttonFab"
					:rounded="buttonRounded"
					x-large
					@click="selectAction">
					<v-icon v-if="buttonIcon" class="action-button-icon">{{ buttonIcon }}</v-icon>
					<span class="action-button-text">{{ buttonText }}</span>
				</v-btn>
			</template>

			<ActionDialog
				v-if="menu"
				ref="actionDialog"
				:actioning-text-override="actioningTextOverride"
				:show-header="true"
				:tab-action="action"
				@close-menu="menu = false" />
		</v-menu>
	</div>
</template>

<script>
	import DeviceUtility from "../helpers/device";
	import ActionDialog from "./ActionDialog.vue";

	export default {
		components: {
			ActionDialog,
		},
		props: {
			buttonId: String,
			buttonType: String,
			buttonIcon: String,
			buttonText: String,
			action: String,
		},
		data() {
			return {
				buttonRounded: false,
				buttonFab: false,
				buttonFabTextPrefix: false,
				vueTelProps: window.VueTelProps,
				starting: false,
				validPhone: false,
				autoSetName: false,
				autoSetNameValue: "",
				menu: false,
				phone: "",
				name: "",
				meetingUrl: "",
				actioningTextOverride: "",
			};
		},
		async mounted() {
			if (this.buttonType == "rounded") {
				this.buttonRounded = true;
			} else if (this.buttonType == "fab") {
				this.buttonFab = true;
			} else if (this.buttonType == "textFab") {
				this.buttonFabTextPrefix = true;
			}
		},
		methods: {
			selectAction() {
				this.menu = true;
				this.$refs.actionDialog?.reset();
			},
			menuInput() {
				console.log("menu input");
			},
		},
		computed: {
			maxWidth() {
				return this.$vuetify.breakpoint.smAndDown ? "100%" : 425;
			},
			alignLeft() {
				return this.$vuetify.breakpoint.smAndDown ? true : false;
			},
		},
	};
</script>
<style scoped>
	.fab-action-header {
		text-align: right;
		margin-right: 16px;
		flex: 1;
	}
</style>
