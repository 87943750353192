<template>
  <v-dialog v-model="visible" width="500" content-class="overflow-hidden" persistent>
    <v-row class="text-center" no-gutters>
      <v-col>
        <v-card
          elevation="2"
          outlined
          shaped
          tile
        >
          <v-card-title>Welcome!</v-card-title>
          <v-card-subtitle v-if="!acceptSMSOnly" style="text-align:left">We need a few pieces of information to finish setting up your account.</v-card-subtitle>
          <v-card-subtitle v-else style="text-align:left">Please agree to receive text message notifications in order to finish setting up your account. </v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="setupForm" :disabled="saving" @submit.prevent>
              <v-text-field
                v-if="!acceptSMSOnly"
                id="organization-name"
                variant="outlined"
                label="The full name of your organization"
                :rules="[rules.required]"
                v-model="setup.organizationName"
              ></v-text-field>

              <v-switch
                inset
                v-model="agreed"
                label="I agree to accept text message notifications for the purpose of joining calls from LiveSwitch Contact. I understand there may be additional costs from my carrier associated with receiving theses text notifications and agree to the charges."
              />
            </v-form>
          </v-card-text>

          <v-card-actions>
            <a v-if="!acceptSMSOnly" href="#" onclick="alert('If you already have an organization account, your administrator must invite you. Please contact your administrator and have them invite you via their team panel.')" style="font-size:12px;padding-left:10px">
              My organization already has an account</a>
            <v-spacer></v-spacer>
            <v-btn
              id="save-org-btn"
              text
              color="primary"
              @click="save"
              :disabled="!agreed || !setupForm"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
  import api from '../api'

  export default {
    name: 'SetupDialog',
    emits: ['setupCompleted'],
    data () {
      return {
        visible: false,
        setup: {
          organizationName: '',
        },
        setupForm: false,
        agreed: true,
        saving: false,
        rules: {
          required: value => !!value || 'Required.',
        },
        acceptSMSOnly: false,
      }
    },
    mounted() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      if (params.acceptSMS) {
        this.acceptSMSOnly = true
      }
    },
    methods: {
      open() {
        this.visible = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      nameToCodename(newName){
        return (newName || '')
          .toLowerCase()
          .replace(/'/g,'')
          .replace(/[^a-z0-9]/g, '-')
          .replace(/-+/g, '-')
      },
      async save(){
        try {
          this.saving = true

          if(!this.$refs.form.validate()){
            this.saving = false
            return
          }

          if (!this.acceptSMSOnly) {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setup.websiteUrl = user.email.replace('@', '')
            this.setup.websiteCodename = this.nameToCodename(this.setup.websiteUrl)
            const org = await api.signupOrganization(this.setup)
            if (org.id) {
              this.$emit('setupCompleted')
              this.visible = false
              api.setOrganizationId(org.id)
              localStorage.setItem('org', JSON.stringify(org))
            } 
          }               
        } catch(e) {
          alert(e.message)
        } finally {
          this.visible = false
          this.saving = false
        }
      },
    },
    watch: {
      visible(val) {
        // reload dialog if they click outside of it to close it.
        if (!val && (!this.agreed || !this.setupForm)) {
          window.location.reload()
        }
      }
    }
  }
</script>
<style scoped>
.overflow-hidden {
  overflow: hidden !important;
}
</style>