export const AsyncPreScreenHead = `
<title>LiveSwitch Contact | Record</title>
<meta name="viewport" content="width=device-width, initial-scale=1" />
<meta name="color-scheme" content="dark">
<style>
    :root {
        color-scheme: dark;
    }
    body, html {
        padding: 0;
        margin: 0;
        background-color: #010021;
        background: radial-gradient(222.26% 131.89% at 50% 131.89%, #015FF8 25%, #010021 65%);
    }
    div.body-wrapper {
        display: flex;
        padding: 60px 0px;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 120px);
    }
    div.middle-display-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;
        flex: 1 0 0;
        align-self: stretch;
    }
    div.middle-display-wrapper > h3 {
        flex: 1 0 0;
        color: #FFF;
        text-align: center;
        font-family: "Helvetica Neue", "Helvetica", "sans-serif";
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -2.56px;
        max-width: 700px;
    }
    div.middle-display-wrapper > img {
        max-width: 160px;
    }
    div.footer-wrapper {
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;
    }
    div.footer-wrapper > img {
        width: 140px;
    }
    @media only screen and (max-width: 600px) and (orientation: portrait) {
        div.body-wrapper {
            padding: 60px 40px;
            width: calc(100% - 80px);
        }
        div.middle-display-wrapper > h3 {
            font-size: 36px;
            letter-spacing: -1.44px;
        }
    }
    @media only screen and (max-height: 600px) and (orientation: landscape) {
        div.body-wrapper {
            padding: 40px 40px;
            width: calc(100% - 80px);
            height: calc(100% - 80px);
        }
        div.middle-display-wrapper > h3 {
            font-size: 36px;
            letter-spacing: -1.44px;
        }
        div.middle-display-wrapper > img {
            display: none;
        }
    }
</style>
`;
export const AsyncPreScreenBody = (url) => `<div class="body-wrapper">
    <div class="middle-display-wrapper">
        <h3>
            You’ll be able to start your recording in just a few seconds.
        </h3>
        <img class="logo-img" src="${url}/logo.svg" ALT="LiveSwitch Logo." />
    </div>
    <div class="footer-wrapper">
        <img class="liveswitch-logo" src="${url}/liveswitch-logo.svg" ALT="LiveSwitch Caption" />
    </div>
</div>`;